
export function all() {
    var fns = arguments;
    return function () {
        for (var idx in fns) {
            if (!fns[idx]) continue;
            fns[idx].apply(null, arguments);
        }
    }
}

export function delay(fn, delay) {
    return function () {
        setTimeout(fn, delay);
    }
}

export function countdown(timeout, onInit, onTick, onTimeout) {
    var remaining = timeout;
    onInit(remaining);
    var timer = setInterval(() => {
        remaining = remaining - 1;
        onTick(remaining);
        if (remaining === 0) {
            onTimeout();
            clearInterval(timer);
        }
    }, 1000);
}