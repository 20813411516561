export default function PingService(props) {
    const { apiUri, lastUpdated, handleUpdate, gliderKey } = props;

    /*const pingTimer = */setInterval(async function () {
        const response = await fetch(apiUri + '/api/v2/ping', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                AppId: 'GLIDER',
                Payload: {
                    MachineHash: `GLIDER-${gliderKey}`,
                    Version: "ShutterPilot.Glider"
                }
            })
        });
        const json = await response.json();
        if (json && json.Payload && Date.parse(json.Payload.UpdatedAt + 'Z') > lastUpdated) {
            handleUpdate()
        }
    }, 30000);

    return null;
}