
var chromaKeyR = 135;
var chromaKeyG = 255;
var chromaKeyB = 120;
var chromaKeyLow = 50;
var chromaKeyHigh = 60;

/*function quickChromaKey(r, g, b) {
    return (g > 100 && r > 100 && b < 43) ? 0 : 1;
}*/

function rgb_to_cb(r, g, b) {
    return Math.round(128 + -0.168736*r - 0.331264*g + 0.5*b);
}

function rgb_to_cr(r, g, b) {
    return Math.round(128 + 0.5*r - 0.418688*g - 0.081312*b);
}

function colorclose(Cb_p, Cr_p, Cb_key, Cr_key, tola, tolb)
{
    /*decides if a color is close to the specified hue*/
    var temp = Math.sqrt((Cb_key-Cb_p)*(Cb_key-Cb_p)+(Cr_key-Cr_p)*(Cr_key-Cr_p));
    if (temp < tola) {return (0.0);}
    if (temp < tolb) {return ((temp-tola)*255/(tolb-tola));}
    return 255;
}

var chromaCb = rgb_to_cb(chromaKeyR, chromaKeyG, chromaKeyB);
var chromaCr = rgb_to_cr(chromaKeyR, chromaKeyG, chromaKeyB);
function slowChromaKey(r, g, b) {
    var cb = rgb_to_cb(r, g, b);
    var cr = rgb_to_cr(r, g, b);
    return colorclose(cb, cr, chromaCb, chromaCr, chromaKeyLow, chromaKeyHigh);
}

export function doChromaKey(video, preview, temp, streamWidth, streamHeight) {
    if (document.hidden || !streamWidth || !streamHeight) return;

    var ctx1 = temp.getContext('2d');
    ctx1.drawImage(video, 0, 0, streamWidth, streamHeight);
    let frame = ctx1.getImageData(0, 0, streamWidth, streamHeight);
    let l = frame.data.length / 4;

    for (let i = 0; i < l; i++) {
        let r = frame.data[i * 4 + 0];
        let g = frame.data[i * 4 + 1];
        let b = frame.data[i * 4 + 2];
        frame.data[i * 4 + 3] = slowChromaKey(r, g, b); //quickChromaKey(r, g, b);
    }
    //ctx2.drawImage(frame, 0, 0);
    preview.getContext('2d').putImageData(frame, 0, 0, 0, 0, streamWidth, streamHeight);
}