import React, { useEffect, useState } from "react";

import PingService from "./services/PingService";
import GliderMonolith from "./GliderMonolith";

//const apiUrl = window.location.protocol + '//' + window.location.host;
//const apiUrl = "https://hub.shutterpilot.com";
//const apiUrl = "https://localhost:5001";
const apiUrl = "";

//let updateQueued = false;
let holdUpdates = false;

export default function Glider(props) {
    const { gliderKey } = props;

    const [campaign, setCampaign] = useState();

    useEffect(() => {
        if (!campaign) {
            async function fetchData() {
                const response = await fetch(
                    `${apiUrl}/api/v2/current-campaign?AppId=GLIDER&MachineHash=GLIDER-${gliderKey}`
                );
                const result = await response.json();
                setCampaign(result.Payload);
            }
            fetchData();
        }
    }, [campaign, setCampaign, gliderKey]);

    if (campaign) {
        return (
            <>
                <PingService
                    apiUri={apiUrl}
                    lastUpdated={Date.now()}
                    gliderKey={gliderKey}
                    handleUpdate={() => {
                        //updateQueued = true;
                        if (!holdUpdates) window.location.reload();
                    }}
                />
                <GliderMonolith
                    apiUri={apiUrl}
                    campaign={campaign}
                    gliderKey={gliderKey}
                />
            </>
        );
    } else {
        return "Loading...";
    }
}
