import React from "react";
import ReactDOM from "react-dom";

import Glider from "./Glider";

let gliderKey = '12cf51b0';

const segments = window.location.pathname.split("/").filter(x => x.length > 0);
if (segments.length === 2 && segments[0] === 'glider' && segments[1].length === 8) {
    gliderKey = segments[1];
}

ReactDOM.render(<Glider gliderKey={gliderKey} />, document.getElementById("app"));
