import React, { useState } from "react";
import { all } from "../utils";

function BackgroundChooserLayoutClass(options) {
    if (!options) return "background-layout-0";

    const count = options.split(";").length;

    if (count <= 2) return "background-layout-2";
    if (count <= 4) return "background-layout-4";
    if (count <= 8) return "background-layout-8";

    return "background-layout-many";
}

export default function BackgroundChooser(props) {
    const {
        localizer: _,
        campaign,
        onChange,
        onConfirm,
        onInteraction,
    } = props;

    const [selection, setSelection] = useState(
        campaign &&
            campaign.ChromaKeyOptions &&
            campaign.ChromaKeyOptions.split(";")[0]
    );

    let options =
        campaign && campaign.ChromaKeyEnabled && campaign.ChromaKeyOptions
            ? campaign.ChromaKeyOptions.split(";").map((background) => (
                  <img
                      key={background}
                      src={background}
                      className={selection === background ? "selected" : ""}
                      style={{
                          borderColor:
                              selection === background
                                  ? campaign.BoothButtonColor || "#DDFFFF"
                                  : campaign.BoothBackgroundColor || "#31313E",
                      }}
                      alt=""
                      onClick={all(
                          onInteraction,
                          () => onChange(background),
                          () => setSelection(background)
                      )}
                  />
              ))
            : null;

    return (
        <div id="backgroundChooser">
            <div
                id="backgroundOptions"
                className={BackgroundChooserLayoutClass(
                    campaign?.ChromaKeyOptions
                )}
            >
                {options}
            </div>
            <button
                id="confirmBackground"
                style={{
                    backgroundColor: campaign.BoothButtonColor || "#DDFFFF",
                    color: campaign.BoothTextColor || "31313E",
                }}
                onClick={all(onInteraction, onConfirm)}
            >
                {_("Choose!")}
            </button>
        </div>
    );
}
