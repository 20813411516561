import React, { useState } from "react";
import { all } from "../utils";

export default function ShareSheet(props) {
    const { localizer: _, campaign, onFinish, onInteraction } = props;

    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();

    const inputStyles = {
        borderColor: campaign.BoothBackgroundColor || "#31313E",
    };
    const buttonStyles = {
        backgroundColor: campaign.BoothButtonColor || "#DDFFFF",
        color: campaign.BoothTextColor || "#31313E",
    };

    return (
        <div id="shareSheet">
            <div>
                <img id="shareImage" width="100%" alt="" />
                <div>
                    <p>
                        {_(
                            "Enter your phone number and/or email address to receive your photo."
                        )}
                    </p>
                    {!campaign || campaign.SmsEnabled ? (
                        <p>
                            {_("Phone Number")}
                            <br />
                            <input
                                type="tel"
                                id="phone"
                                style={inputStyles}
                                name="phone"
                                pattern="[0-9]*"
                                inputMode="numeric"
                                onChange={all(onInteraction, (e) =>
                                    setPhone(e.target.value)
                                )}
                            />
                        </p>
                    ) : null}
                    {!campaign || campaign.EmailEnabled ? (
                        <p>
                            {_("Email Address")}
                            <br />
                            <input
                                type="email"
                                id="email"
                                style={inputStyles}
                                name="email"
                                onChange={all(onInteraction, (e) =>
                                    setEmail(e.target.value)
                                )}
                            />
                        </p>
                    ) : null}
                    {/*p><input type="checkbox" id="optin" name="optin" checked />&nbsp;Please send me additional information</p>*/}
                </div>
                <div>
                    <button
                        id="send"
                        style={{ ...inputStyles, ...buttonStyles }}
                        onClick={() => onFinish(phone, email)}
                    >
                        {_("Get my photo")}
                    </button>
                </div>
            </div>
        </div>
    );
}
