import React from "react";

export default function CountdownLayer(props) {
    const { value, campaign } = props;
    return (
        <div
            id="countdown"
            style={{ color: campaign.BoothTextColor || "31313E" }}
        >
            {value}
        </div>
    );
}
