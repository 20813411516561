import React, { useEffect, useState } from "react";

import { doChromaKey } from "../chromakey";

let refStreamWidth, refStreamHeight;
let video, preview;

const temp = document.createElement("canvas");
//document.body.append(temp); // FOR DEBUGGING

export default function Container(props) {
    const { localizer: _, campaign, onStart } = props;

    const [streamWidth, setStreamWidth] = useState();
    const [streamHeight, setStreamHeight] = useState();
    const [previewWidth, setPreviewWidth] = useState();
    const [previewHeight, setPreviewHeight] = useState();

    var stage;

    refStreamWidth = streamWidth;
    refStreamHeight = streamHeight;

    function startChromaKey() {
        video.style.display = "none";
        preview.style.display = "block";
        const boundChromaKey = () =>
            doChromaKey(video, preview, temp, refStreamWidth, refStreamHeight);
        boundChromaKey();
        setInterval(boundChromaKey, 33);
    }

    function updateStageSize(sw, sh) {
        sw = sw || streamWidth;
        sh = sh || streamHeight;

        if (!sw || !sh) return;

        var scale = Math.min(
            widthInContainer(stage) / sw,
            stage.clientHeight / sh
        );

        temp.width = sw;
        temp.height = sh;
        setPreviewWidth(sw * scale);
        setPreviewHeight(sh * scale);
    }

    useEffect(() => {
        if (!previewHeight || !previewWidth) updateStageSize();

        if (!video.srcObject) {
            async function init() {
                window.addEventListener("resize", function (e) {
                    updateStageSize();
                });

                // These are required to make it work on iOS
                //video.setAttribute('autoplay', '');
                //video.setAttribute('muted', '');
                //video.setAttribute('playsinline', '');

                const constraints = {
                    video: true,
                };

                try {
                    const stream = await navigator.mediaDevices.getUserMedia(
                        constraints
                    );
                    video.srcObject = stream;
                } catch (err) {
                    alert("No camera found: " + err);
                }
            }
            init();
        }
    });

    function widthInContainer(el) {
        return (
            parseFloat((el.currentStyle || getComputedStyle(el)).marginLeft) +
            parseFloat((el.currentStyle || getComputedStyle(el)).marginLeft) +
            el.clientWidth
        );
    }

    return (
        <div
            className="container"
            style={{
                backgroundColor: campaign.BoothBackgroundColor || "#31313E",
            }}
        >
            <header
                style={{
                    backgroundColor: campaign.BoothButtonColor || "#DDFFFF",
                    color: campaign.BoothTextColor || "31313E",
                }}
            >
                <p>{_('Touch or click "Start"')}</p>
            </header>
            <div
                id="stage"
                ref={(el) => (stage = el)}
                style={{
                    width: previewWidth || "100%",
                    height: previewHeight || "auto",
                }}
            >
                <div id="background"></div>
                <video
                    autoPlay
                    muted
                    playsInline
                    ref={(el) => (video = el)}
                    style={{ transform: "scaleX(-1)" }}
                    onLoadedMetadataCapture={(e) => {
                        if (streamWidth && streamHeight) return;

                        setStreamWidth(e.target.videoWidth);
                        setStreamHeight(e.target.videoHeight);

                        updateStageSize(
                            e.target.videoWidth,
                            e.target.videoHeight
                        );
                        if (campaign?.ChromaKeyEnabled ?? false) {
                            startChromaKey();
                        }
                    }}
                ></video>
                <canvas
                    id="preview"
                    ref={(el) => (preview = el)}
                    style={{ display: "none", transform: "scaleX(-1)" }}
                    width={streamWidth}
                    height={streamHeight}
                ></canvas>
                {campaign.OverlayEnabled ? (
                    <div
                        id="overlay"
                        style={{
                            backgroundImage: `url(${campaign.OverlayImage})`,
                        }}
                    ></div>
                ) : null}
            </div>
            <footer
                style={{
                    backgroundColor: campaign.BoothButtonColor || "#DDFFFF",
                    color: campaign.BoothTextColor || "31313E",
                }}
            >
                <button
                    id="start"
                    onClick={onStart}
                    style={{ color: campaign.BoothTextColor || "31313E" }}
                >
                    {_("Start")}
                </button>
            </footer>
        </div>
    );
}
