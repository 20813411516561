import React, { useState } from "react";

import { all, delay, countdown } from "./utils";
import Container from "./components/Container";
import CountdownLayer from "./components/CountdownLayer";
import BackgroundChooser from "./components/BackgroundChooser";
import ShareSheet from "./components/ShareSheet";

import "./glider.css";

export default function GliderMonolith(props) {
    const { campaign, apiUri, gliderKey } = props;

    const localizer = (s) => s;

    var updateQueued = false;

    const [currentBackground, setCurrentBackground] = useState<string[]>([]);
    const [currentImage, setCurrentImage] = useState<string[]>([]);
    var tempBackground = "";
    var startButtonEnabled = true;
    //var holdUpdates = false; // Basically, are we on the attract screen
    const [photoPending, setPhotoPending] = useState(false);
    var currentShot = 0;

    function startSession() {
        if (!startButtonEnabled) return;

        resetUiTimer();
        //holdUpdates = true;

        startButtonEnabled = false;

        startShot();
    }

    function startShot() {
        resetUiTimer();

        if (campaign?.ChromaKeyEnabled ?? false) {
            showBackgroundChooser();
        } else {
            confirmBackground();
        }
    }

    function showBackgroundChooser() {
        resetUiTimer();

        document.getElementById("backgroundChooser")!.style.display = "block";

        var chooser = document.getElementById("backgroundChooser")!;
        var optionsList = chooser.querySelector("#backgroundOptions")!;

        optionsList.querySelectorAll("img").forEach(function (el) {
            el.classList.remove("selected");
        });
        optionsList.querySelectorAll("img")[0].click();
    }

    async function postPhoto(phone, email) {
        setPhotoPending(false);
        await fetch(`${apiUri}/glider/${gliderKey}/post`, {
            method: "PUT",
            //credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                RequestVerificationToken: await GetAntiXsrfRequestToken(),
            },
            body: JSON.stringify({
                photo: currentImage,
                phone,
                email,
                background: currentBackground,
                //optin: document.getElementById('optin').checked == 'checked'
            }),
        });
        reset();
    }

    let uiTimer: number | undefined = undefined;
    function reset() {
        clearTimeout(uiTimer);
        //holdUpdates = false;
        if (updateQueued) window.location.reload();

        // TODO: Send photo if not yet sent

        startButtonEnabled = true;
        var phoneField = document.getElementById("phone") as HTMLInputElement;
        var emailField = document.getElementById("email") as HTMLInputElement;
        if (phoneField) {
            phoneField.value = "";
        }
        if (emailField) {
            emailField.value = "";
        }
        //document.getElementById('optin').checked = 'checked';
        setCurrentImage([]);
        setCurrentBackground([]);

        hideBackgroundChooser();
        hideShare();
    }

    function resetUiTimer() {
        //var before = uiTimer;
        if (uiTimer !== null) clearTimeout(uiTimer);
        uiTimer = setTimeout(function () {
            if (photoPending) postPhoto(null, null);
            reset();
        }, 30000);
        //console.log("Reset timer. Before: " + before + "; after: " + uiTimer);
    }

    function showShare() {
        document.getElementById("shareSheet")!.style.display = "flex";
    }

    function hideShare() {
        document.getElementById("shareSheet")!.style.display = "none";
    }

    /*function removeQuery(path) {
        var idx = path.indexOf("?");
        if (idx < 0) return path;
        return path.substring(0, idx);
    }*/

    function hideBackgroundChooser() {
        document.getElementById("backgroundChooser")!.style.display = "none";
    }

    function GetAntiXsrfRequestToken() {
        //var response = await fetch(`${apiUri}/glider/${gliderKey}`, { /*credentials: 'include'*/ });
        //var html = await response.text();
        //var div = document.createElement('div');
        //div.innerHTML = html;
        return ""; //div.querySelector('input#RequestVerificationToken').value;
    }

    function showCountdown(countdown) {
        var elCountdown = document.getElementById("countdown")!;
        elCountdown.innerText = "" + countdown;
        elCountdown.style.display = "block";
        elCountdown.style.lineHeight = window.innerHeight + "px";
    }

    function updateCountdown(countdown) {
        document.getElementById("countdown")!.innerText = "" + countdown;
    }

    function hideCountdown() {
        document.getElementById("countdown")!.style.display = "none";
    }

    function flash() {
        var flash = document.querySelector(".container") as HTMLDivElement;
        flash.style.backgroundColor = "white";
        var color = 256;
        var timer = setInterval(() => {
            color = color - 16;
            if (color <= 0) {
                flash.style.backgroundColor =
                    campaign.BoothBackgroundColor || "#31313E";
                clearInterval(timer);
            }
            flash.style.backgroundColor =
                "rgba(255, 255, 255, " + color / 256 + ")";
        }, 50);
    }

    function grab() {
        var doChromaKey = campaign && campaign.ChromaKeyEnabled;
        var image;
        if (doChromaKey) {
            image = (
                document.getElementById("preview") as HTMLCanvasElement
            ).toDataURL();
        } else {
            const video = document.querySelector("video")!;
            var canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            var ctx = canvas.getContext("2d");
            ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
            image = canvas.toDataURL("image/jpg");
        }
        setCurrentImage([...currentImage, image.split(",")[1]]);
        setCurrentBackground([...currentBackground, tempBackground]);
        (document.getElementById("shareImage") as HTMLImageElement).src = image;
    }

    function confirmBackground() {
        resetUiTimer();
        hideBackgroundChooser();

        // Show a countdown, then flash the screen and grab a frame
        countdown(
            5,
            showCountdown,
            updateCountdown,
            all(hideCountdown, flash, grab, delay(finalizeShot, 1000))
        );
    }

    function finalizeShot() {
        setPhotoPending(true);
        const numShots =
            campaign && campaign.MultishotEnabled
                ? campaign?.MultishotCount ?? 1
                : 1;
        currentShot++;
        if (currentShot < numShots) {
            startShot();
        } else {
            currentShot = 0;
            showShare();
        }
    }

    function selectBackground(uri) {
        tempBackground = uri;
        (
            document.querySelector("#stage #background") as HTMLDivElement
        ).style.backgroundImage = "url(" + uri + ")";
    }

    return (
        <>
            <Container
                localizer={localizer}
                campaign={campaign}
                onStart={startSession}
            />
            <CountdownLayer localizer={localizer} campaign={campaign} />
            <BackgroundChooser
                localizer={localizer}
                campaign={campaign}
                onChange={selectBackground}
                onConfirm={confirmBackground}
                onInteraction={resetUiTimer}
            />
            <ShareSheet
                localizer={localizer}
                campaign={campaign}
                onFinish={all(hideShare, postPhoto)}
                onInteraction={resetUiTimer}
            />
        </>
    );
}
